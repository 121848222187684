import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { apiUrl } from '../services/http/http';
import './Profile.css';
import user_icon from './user_icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import auth from '../services/util/auth';
import masterListSource from './MasterList';
import GuestHelpHomepage from './GuestHelpHomepage';
import { confirmAlert } from 'react-confirm-alert';

const ProfileList = () => {
  const [profiles, setProfiles] = useState();
  const [frameworkName, setFrameworkName] = useState('iscb');
  const [version, setVersion] = useState('3.0');
  const [loading, setLoading] = useState(false);
  const [guestProfile, setGuestProfile] = useState();
  const [profileWrapper, setProfileWrapper] = useState({
    height: '170px',
    overflow: 'hidden'
  });
  const [showFull, setShowFull] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let tempguestProfile = JSON.parse(localStorage.getItem('guestProfile'));
        setGuestProfile(tempguestProfile ? tempguestProfile : '');
        await fetch(
          `${apiUrl}/api/${frameworkName}/${version}/profiles/?_format=json&source=competencyhub&timestamp=`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            setProfiles(findresponse);
            setLoading(false);
          });
      } catch (e) {
        console.log(e.message);
        setLoading(false);
      }
    };

    fetchData();
    if (showFull) {
      setProfileWrapper({ height: '100%' });
    } else {
      // setProfileWrapper({ height: '170px', overflow: 'hidden' });
      setProfileWrapper({ height: '250px', overflow: 'hidden' });
    }
  }, [version, frameworkName, showFull]);

  const changeViewMore = () => {
    if (showFull) {
      setShowFull(false);
    } else {
      setShowFull(true);
    }
  };

  const onSelectChange = e => {
    let frmVersion = masterListSource.filter(
      item => item.title === e.target.value
    )[0].version;
    if (frmVersion) {
      setFrameworkName(e.target.value);
      setVersion(frmVersion);
    } else {
      alert('wrong selection');
    }
    console.log(frameworkName + ' ' + frmVersion);
  };

  const openModal = e => {
    e.preventDefault();
    setModalOpen(true);
  };

  const closeModal = () => {
    // e.preventDefault();
    setModalOpen(false);
  };

  const handleDelete = e => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>Delete my profile</h2>
            <p>
              Are you sure you want to delete your profile? This action cannot
              be undone!!
            </p>
            <div className="react-confirm-alert-button-group">
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  localStorage.removeItem('guestProfile');
                  localStorage.removeItem('bannerDismissed');
                  alert('Profile deleted');
                  window.location.reload(false);
                  onClose();
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div>
      <div style={{ width: '40%' }}>
        <div className="vf-form__item vf-stack">
          <label className="vf-form__label" htmlFor="vf-form__select">
            Select life science area
          </label>
          <select
            className="vf-form__select"
            id="vf-form__select"
            onChange={e => onSelectChange(e)}
          >
            <option value="iscb">
              Computational biology students and professionals
            </option>
            <option value="bioexcel">
              Computational biomolecular research professionals
            </option>
            <option value="permedcoe">
              Computational personalised medicine professionals
            </option>
          </select>
        </div>
      </div>
      <div className="vf-u-padding__top--400" />
      <div className="wrapper" style={profileWrapper}>
        <div className="vf-grid vf-grid__col-3">
          <div className="vf-grid__col--span-2">
            <div className="vf-grid vf-grid__col-2">
              {profiles ? (
                profiles.map((profile, key) => {
                  if (!auth.currently_logged_in_user.is_logged_in) {
                    if (profile.publishing_status === 'Live') {
                      return (
                        <div key={key}>
                          <article className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                            {profile.image[0] ? (
                              <img
                                alt=""
                                src={profile.image[0].url}
                                className="vf-profile__image"
                                width={'auto'}
                                height={'auto'}
                                loading="lazy"
                              />
                            ) : (
                              <img
                                alt=""
                                src={user_icon}
                                className="vf-profile__image"
                              />
                            )}
                            <h4 className="vf-profile__title">
                              {profile.job_title ? (
                                <a
                                  className="vf-profile__link"
                                  href={`/framework/${frameworkName}/${version}/profile/view/${
                                    profile.id
                                  }${profile.url_alias}`}
                                >
                                  {profile.job_title}
                                </a>
                              ) : (
                                'Job title'
                              )}
                            </h4>
                          </article>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div key={key}>
                        <article className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                          {profile.image[0] ? (
                            <img
                              alt=""
                              src={profile.image[0].url}
                              className="vf-profile__image"
                              width={'auto'}
                              height={'auto'}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              alt=""
                              src={user_icon}
                              className="vf-profile__image"
                            />
                          )}
                          <h4 className="vf-profile__title">
                            {profile.job_title ? (
                              <a
                                className="vf-profile__link"
                                href={`/framework/${frameworkName}/${version}/profile/view/${
                                  profile.id
                                }${profile.url_alias}`}
                              >
                                {profile.job_title}
                              </a>
                            ) : (
                              'Job title'
                            )}
                          </h4>
                        </article>
                      </div>
                    );
                  }
                  return null;
                })
              ) : loading ? (
                <center>
                  <img alt="progress" src="/progressbar.gif" />
                  <h4>Loading resources</h4>
                </center>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            {guestProfile ? (
              <>
                <div className="vf-section-header">
                  <h2
                    className="vf-section-header__heading vf-section-header__heading--is-link"
                    id="section-link"
                  >
                    <a
                      href={`/framework/${guestProfile.frameworkName}/${
                        guestProfile.versionNumber
                      }/profile/view/guest`}
                    >
                      Your profile
                    </a>
                    <svg
                      aria-hidden="true"
                      className="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end"
                      width="1em"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z"
                        fill=""
                        fillRule="nonzero"
                      />
                    </svg>
                  </h2>
                </div>
                <div className="vf-u-margin__top--400" />
                {guestProfile.frameworkName === frameworkName ? (
                  <>
                    <p>
                      Assess your profile against others and find training
                      resources to fill the gaps
                    </p>
                    <div className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                      <img
                        alt=""
                        src={
                          guestProfile.image[0]
                            ? guestProfile.image[0].url
                            : user_icon
                        }
                        className="vf-profile__image"
                        width={'auto'}
                        height={'auto'}
                        loading="lazy"
                      />
                      <div>
                        <h4 className="vf-profile__title">
                          {guestProfile.title}
                        </h4>
                        <p className="vf-profile__phone | vf-u-last-item ">
                          <Link
                            className="vf-profile__link"
                            to={`/framework/${guestProfile.frameworkName}/${
                              guestProfile.versionNumber
                            }/profile/view/guest`}
                          >
                            {guestProfile.job_title}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <hr className="vf-divider | vf-u-fullbleed" />
                    <Link onClick={e => handleDelete(e)} to={`#`}>
                      Delete your profile{' '}
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="vf-banner vf-banner--alert vf-banner--info">
                      <div className="vf-banner__content">
                        <p className="vf-banner__text">
                          You have created a profile in the{' '}
                          {
                            masterListSource.filter(
                              item => item.title === guestProfile.frameworkName
                            )[0].short_desc
                          }{' '}
                          framework. We can only store one profile. To assess
                          yourself in a different framework, you need to delete
                          it and create a new profile.
                          <button
                            className="vf-button vf-button--link"
                            onClick={e => openModal(e)}
                            style={{
                              fontWeight: 'normal',
                              paddingLeft: 0
                            }}
                          >
                            More information
                          </button>{' '}
                          <Link onClick={e => handleDelete(e)} to={`#`}>
                            Delete your profile{' '}
                          </Link>
                        </p>
                        <GuestHelpHomepage
                          modalOpen={modalOpen}
                          closeModal={closeModal}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h4>
                  Create your{' '}
                  {
                    masterListSource.filter(
                      item => item.title === frameworkName
                    )[0].short_desc
                  }{' '}
                  profile{' '}
                </h4>
                <p>
                  Assess your profile against others and find training resources
                  to fill the gaps
                </p>
                <article>
                  <Link
                    className="vf-button vf-button--primary vf-button--sm"
                    to={`/framework/${frameworkName}/${version}/profile/create/guest`}
                  >
                    Add your profile{' '}
                  </Link>
                </article>
              </>
            )}
          </div>
        </div>
      </div>
      {profiles &&
      profiles.filter(profile => profile.publishing_status === 'Live').length >
        6 ? (
        <button
          className="vf-button vf-button--link"
          onClick={() => changeViewMore()}
          style={{ fontWeight: 'normal' }}
        >
          {showFull ? 'View less' : 'View more'}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProfileList;
