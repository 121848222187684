import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { apiUrl } from '../services/http/http';
import ProfileService from '../services/profile/profile';
import ReactTooltip from 'react-tooltip';
import masterListSource from './MasterList';

export const ProfileMapGuest = props => {
  const profileService = new ProfileService();

  const [profile, setProfile] = useState();
  const [framework, setFramework] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  const [mapping, setMapping] = useState();
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];

  var competencyForm = '';
  var expertise_levels = [];
  var expertise_levels_legend = [];
  var expertise_not_applicable = '';
  var attribute_types = [];

  useEffect(() => {
    const fetchData = async () => {
      const storedProfile = JSON.parse(localStorage.getItem('guestProfile'));
      if (storedProfile) {
        setProfile(storedProfile);
        setMapping(
          storedProfile.profile_mapping ? storedProfile.profile_mapping : []
        );
      }

      await fetch(
        `${apiUrl}/api/version_manager?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFrameworkInfo(findresponse);
        });

      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFramework(findresponse);
        });
    };

    if (!mapping) {
      fetchData();
    }
  }, [frameworkName, frameworkVersion, mapping]);

  const handleSubmit = async e => {
    e.preventDefault();

    let response = await profileService.mapProfileGuest(mapping);
    if (response) {
      props.history.push(
        `/framework/${frameworkName}/${frameworkVersion}/profile/view/guest`
      );
    } else {
      alert('There has been a problem. Please contact the administrator.');
    }
  };

  const handleSelect = event => {
    let competency_id = event.target[event.target.selectedIndex].getAttribute(
      'data-competency'
    );
    let expertise_id = event.target[event.target.selectedIndex].getAttribute(
      'data-expertise'
    );
    let tempMapping = mapping;

    let attributesList = [];
    let checkBoxes = document.querySelectorAll(
      '[data-competency="' + competency_id + '"]'
    );

    if (expertise_id === expertise_not_applicable) {
      checkBoxes.forEach(function(index, item, arr) {
        index.checked = false;
        index.disabled = true;
      });

      // Remove the NA competency from the temp array
      tempMapping = tempMapping.filter(o => o.competency !== competency_id);
    } else if (tempMapping) {
      if (tempMapping.find(o => o.competency === competency_id)) {
        let o = tempMapping.find(o => o.competency === competency_id);
        o.expertise = expertise_id;

        checkBoxes.forEach(function(index, item, arr) {
          attributesList.push(index.id);
          index.checked = true;
          index.disabled = false;
        });
        o.attributes = attributesList;
      } else {
        checkBoxes.forEach(function(index, item, arr) {
          attributesList.push(index.id);
          // item.checked = true;
          // item.disabled = false;
          index.checked = true;
          index.disabled = false;
        });
        tempMapping.push({
          competency: competency_id,
          expertise: expertise_id,
          attributes: attributesList
        });
      }
    }
    setMapping(tempMapping);
  };

  // const handleCheckBox = e => {
  //   let checkboxStatus = e.target.checked;
  //   let competency_id = e.target.dataset.competency;
  //   let tempMapping = mapping;
  //   if (tempMapping) {
  //     if (checkboxStatus === true) {
  //       e.target.checked = true;
  //       let o = tempMapping.find(o => o.competency === competency_id);
  //       o.attributes.push(e.target.id);
  //     } else {
  //       let o = tempMapping.find(o => o.competency === competency_id);
  //       o.attributes.splice(o.attributes.indexOf(e.target.id), 1);
  //     }
  //     setMapping(tempMapping);
  //   }
  //   console.log(mapping);
  // };

  const setExpertiseLevels = levels => {
    // let levels_inorder = levels.reverse;
    // levels_inorder = [...levels].reverse();
    levels.map((level, index) => {
      if (level.title === 'Not applicable') {
        expertise_not_applicable = level.id;
        expertise_levels[level.id] = level.title;
      } else {
        expertise_levels[level.id] =
          'Level ' + level.rating_level + ': ' + level.title;
      }

      // expertise_not_applicable =
      //   level.title === 'Not applicable' ? level.id : '';

      if (level.title !== 'Not applicable') {
        expertise_levels_legend.push(
          <li key={index} className="vf-list__item">
            <div
              data-tip={level.description ? level.description : 'NA'}
              data-html={true}
              data-type="info"
              data-multiline={true}
            >
              <span className="vf-badge vf-badge--tertiary">
                {level.rating_level}
              </span>
              <div> {level.title}</div>
            </div>
            <ReactTooltip className="tooltip-custom" />
          </li>
        );
      }

      return null;
    });
  };

  const generateForm = () => {
    if (frameworkInfo) {
      frameworkInfo.forEach(info => {
        if (info.title.toLowerCase() === frameworkName) {
          // frameworkFullName = info.title;
          setExpertiseLevels(info.expertise_levels);

          // info.expertise_levels.map(
          //   (level, levelIndex) => (
          //     expertise_levels[level.id] = level.title,
          //     level.title === 'Not applicable'
          //       ? (expertise_not_applicable = level.id)
          //       : '',
          //     expertise_levels_legend.push(
          //       <li
          //         key={levelIndex}
          //         className="vf-list__item"
          //         style={{ textAlign: 'center' }}
          //       >
          //         <div
          //           data-tip={level.description ? level.description : 'NA'}
          //           data-html={true}
          //           data-type="info"
          //           data-multiline={true}
          //         >
          //           <span className="vf-badge vf-badge--tertiary">
          //             {' '}
          //             {level.rating_level}{' '}
          //           </span>{' '}
          //           <span> {level.title}</span>
          //         </div>
          //         <ReactTooltip className="tooltip-custom" />
          //       </li>
          //     )
          //   )
          // );
        }
      });
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    if (framework) {
      competencyForm = framework.map(item =>
        item.domains.map((domain, did) => (
          <ul key={`ul${did}`}>
            <li key={did} className="domain_list">
              <div>
                <div>
                  <h4 className="domain_title"> {domain.title}</h4>
                </div>
              </div>
              <ul>
                {domain.competencies.map((competency, cid) => (
                  <li key={cid} className="competency_list">
                    <div className="vf-grid vf-grid__col-4">
                      <div className="vf-grid__col--span-3">
                        <details className="vf-details">
                          <summary className="vf-details--summary">
                            {competency.title}
                          </summary>
                          <ul className="vf-list">
                            {attribute_types.map(
                              (attribute_type, typeIndex) => {
                                return (
                                  <li key={typeIndex} className="vf-list--li">
                                    <h5>{attribute_type}</h5>

                                    <ul>
                                      {competency.attributes
                                        .filter(
                                          attribute =>
                                            attribute.type === attribute_type
                                        )
                                        .map((attribute, attrIndex) => (
                                          <li key={attrIndex}>
                                            {attribute.title}
                                          </li>
                                        ))}
                                    </ul>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </details>
                      </div>
                      <div className="vf-grid__col--span-1">
                        <label
                          className="vf-form__label vf-u-sr-only"
                          htmlFor={'select_scale_' + competency.id}
                        >
                          Select a scale
                        </label>
                        <select
                          onChange={e => handleSelect(e)}
                          defaultValue={
                            mapping
                              ? mapping.find(
                                  o => o.competency === competency.id
                                )
                                ? mapping.find(
                                    o => o.competency === competency.id
                                  ).expertise
                                : expertise_not_applicable
                              : expertise_not_applicable
                          }
                          className="vf-form__select"
                          id={'select_scale_' + competency.id}
                        >
                          {expertise_levels.map((key, value) => (
                            <option
                              key={value}
                              value={value}
                              data-expertise={value}
                              data-competency={competency.id}
                            >
                              {key}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        ))
      );
    }
  };

  return (
    <div>
      {generateForm()}
      {profile ? (
        <div key={'div123'}>
          <div key={'div567'} className="row">
            <div className="vf-u-margin__top--400" />
            <h1>
              Create a profile in{' '}
              {
                masterListSource.filter(item => item.title === frameworkName)[0]
                  .short_desc
              }{' '}
            </h1>
            {/* <div>
              <i className="icon icon-common icon-exclamation-triangle" /> Your
              profile will be saved in your browser.{' '}
              <button
                className="vf-button vf-button--link"
                onClick={e => openModel(e) }
              >
                Get more information
              </button>
              <GuestHelp modalOpen={modalOpen} closeModal={closeModal()} />
            </div> */}
            <hr />

            <div key={'div350'} className="column medium-12">
              <div>
                <h2>Assign competencies</h2>
                <p>
                  Select your level of expertise in each competency from the
                  dropdown menu. Choose the knowledge, skills and attitudes that
                  are applicable to you by clicking on each competency to view
                  them.
                </p>
              </div>
              <div className="vf-u-margin__top--800" />
              <h3>Scale</h3>
              <p>A guide to assign your expertise</p>
              <ul className="vf-list legend-inline">
                {expertise_levels_legend}
              </ul>
              <hr />

              <div>
                {
                  <form key={profile.id} onSubmit={e => handleSubmit(e)}>
                    {competencyForm}
                    <div className="submit_fixed">
                      <button
                        className="vf-button vf-button--primary vf-button--sm"
                        type="submit"
                      >
                        Save <i className="icon icon-common icon-save" />
                      </button>
                    </div>
                  </form>
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        'Loading'
      )}
    </div>
  );
};

export const path = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profile/map/guest"
      component={ProfileMapGuest}
    />
  </Switch>
);

export default path;
