import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';

import { apiUrl } from '../services/http/http';
import './Profile.css';
import user_icon from './user_icon.png';
import { ProfileComparisonModal } from '../../shared/components/ProfileComparisonModal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import auth from '../services/util/auth';
import masterListSource from './MasterList';

const ProfileList = props => {
  let history = useHistory();

  const frameworkName = props.framework;
  const frameworkVersion = props.version;
  const [guestProfile, setGuestProfile] = useState();
  const [profiles, setProfiles] = useState(null);
  const [userFrameworks, setUserFrameworks] = useState([]);
  const [comparisonError, setComparisonError] = useState(null);
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [cloneResponse, setCloneResponse] = useState('');

  // var profilesToCompare = [];
  const needTimeStamp = useMemo(() => {
    return auth.currently_logged_in_user.is_logged_in ? Date.now() : '';
  }, []);

  var userName = auth.currently_logged_in_user.username;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let tempguestProfile = JSON.parse(localStorage.getItem('guestProfile'));
        setGuestProfile(tempguestProfile ? tempguestProfile : '');
        await fetch(
          `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles/?_format=json&source=competencyhub&timestamp=${needTimeStamp}`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            setProfiles(findresponse);
          });
        if (userName) {
          await fetch(`${apiUrl}/api/authorisation/${userName}?_format=json`, {
            method: 'GET',
            credentials: 'include'
          })
            .then(Response => Response.json())
            .then(findresponse => {
              setUserFrameworks(findresponse);
            });
        }
      } catch (e) {
        console.log(e.message);
      }
    };

    fetchData();
  }, [frameworkVersion, frameworkName, userName, needTimeStamp]);

  const redirectToCompare = e => {
    if (selectedProfileId) {
      history.push(
        `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${selectedProfileId}`
      );
    } else {
      setComparisonError('Select a role to compare');
    }
  };

  const cloneProfiles = async () => {
    await fetch(
      `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles_clone/?_format=json`,
      {
        method: 'GET',
        credentials: 'include'
      }
    )
      .then(Response => Response.json())
      .then(findresponse => {
        setCloneResponse(findresponse);
      });
  };

  const handleDelete = e => {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <h2>Delete my profile</h2>
            <p>
              Are you sure you want to delete your profile? This action cannot
              be undone!!
            </p>
            <div className="react-confirm-alert-button-group">
              <button onClick={onClose}>No</button>
              <button
                onClick={() => {
                  localStorage.removeItem('guestProfile');
                  localStorage.removeItem('bannerDismissed');
                  alert('Profile deleted');
                  window.location.reload(false);
                  onClose();
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          </div>
        );
      }
    });
  };

  const checkFMAccess = () => {
    var temp = [];
    if (userFrameworks.length > 0) {
      userFrameworks.forEach(item => {
        // temp.push(item.toLowerCase().replace(/ /g, ''));
        temp.push(item.framework_machine_name);
      });
      if (temp.includes(frameworkName)) {
        return true;
      }
      return false;
    }
  };
  return (
    <div>
      <div className="introduction">
        <h3>Discover and explore</h3>
        <p>
          Discover and explore career profiles in{' '}
          {
            masterListSource.filter(item => item.title === frameworkName)[0]
              .short_desc
          }{' '}
        </p>
      </div>
      <div className="wrapper">
        <div className="vf-grid vf-grid__col-2">
          <div>
            <span>
              {auth.currently_logged_in_user.roles.length > 0 &&
              checkFMAccess() ? (
                <span>
                  <Link
                    className="vf-button vf-button--primary vf-button--sm"
                    to={`/framework/${frameworkName}/${frameworkVersion}/profile/create/`}
                  >
                    {' '}
                    Create reference profile{' '}
                    <i className="icon icon-common icon-user-plus" />
                  </Link>
                  <div className="vf-u-margin__bottom--400" />
                </span>
              ) : (
                <span />
              )}
            </span>
          </div>
        </div>
        <div className="vf-grid vf-grid__col-4">
          <div className="vf-grid__col--span-3">
            <div className="vf-grid vf-grid__col-2">
              {profiles !== null && profiles.length > 0 ? (
                profiles.map((profile, index) => {
                  if (!auth.currently_logged_in_user.is_logged_in) {
                    if (profile.publishing_status === 'Live') {
                      return (
                        <div key={index}>
                          <article className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                            {profile.image[0] ? (
                              <img
                                alt=""
                                src={profile.image[0].url}
                                className="vf-profile__image"
                                width={'auto'}
                                height={'auto'}
                                loading="lazy"
                              />
                            ) : (
                              <img
                                alt=""
                                src={user_icon}
                                className="vf-profile__image"
                              />
                            )}
                            <h4 className="vf-profile__title">
                              {profile.job_title ? (
                                <a
                                  className="vf-profile__link"
                                  href={`/framework/${frameworkName}/${frameworkVersion}/profile/view/${
                                    profile.id
                                  }${profile.url_alias}`}
                                >
                                  {profile.job_title}
                                </a>
                              ) : (
                                'Job title'
                              )}
                            </h4>
                          </article>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div key={index}>
                        <article
                          className={`vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline ${
                            profile.publishing_status
                          }`}
                        >
                          {profile.image[0] ? (
                            <img
                              alt=""
                              src={profile.image[0].url}
                              className="vf-profile__image"
                              width={'auto'}
                              height={'auto'}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              alt=""
                              src={user_icon}
                              className="vf-profile__image"
                            />
                          )}
                          <h4 className="vf-profile__title">
                            {profile.job_title ? (
                              <a
                                className="vf-profile__link"
                                href={`/framework/${frameworkName}/${frameworkVersion}/profile/view/${
                                  profile.id
                                }${profile.url_alias}`}
                              >
                                {profile.job_title}
                              </a>
                            ) : (
                              'Job title'
                            )}
                          </h4>
                        </article>
                      </div>
                    );
                  }
                  return null;
                })
              ) : (
                <span>
                  {auth.currently_logged_in_user.roles.length > 0 &&
                  checkFMAccess() ? (
                    <span>
                      {cloneResponse !== '' ? (
                        <div className="vf-banner vf-banner--alert vf-banner--info">
                          <div className="vf-banner__content">
                            {cloneResponse.message}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      <button
                        className="vf-button vf-button--primary vf-button--sm"
                        onClick={cloneProfiles}
                      >
                        {' '}
                        Clone reference profiles from previous version{' '}
                        <i className="icon icon-common icon-user-plus" />
                      </button>
                      <div className="vf-u-margin__bottom--400" />
                    </span>
                  ) : (
                    <span />
                  )}
                </span>
              )}
            </div>
          </div>
          <div>
            {guestProfile ? (
              <>
                <h4>Your profile</h4>
                {/* <p>
                  Browse your competencies and assess your profile against
                  others
                </p> */}
                {guestProfile.frameworkName === frameworkName ? (
                  <p>
                    Assess your profile against others and find training
                    resources to fill the gaps
                  </p>
                ) : (
                  ''
                )}
                <div className="vf-u-margin__top--400" />
                <hr className="vf-divider | vf-u-fullbleed" />
                <div className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                  <img
                    alt=""
                    src={
                      guestProfile.image[0]
                        ? guestProfile.image[0].url
                        : user_icon
                    }
                    className="vf-profile__image"
                    width={'auto'}
                    height={'auto'}
                    loading="lazy"
                  />
                  <div>
                    <h4 className="vf-profile__title">{guestProfile.title}</h4>
                    <p className="vf-profile__phone | vf-u-last-item ">
                      <Link
                        className="vf-profile__link"
                        to={`/framework/${guestProfile.frameworkName}/${
                          guestProfile.versionNumber
                        }/profile/view/guest`}
                      >
                        {guestProfile.job_title}
                      </Link>
                    </p>
                  </div>
                </div>
                <div>
                  <div className="vf-u-margin__top--400" />
                  {guestProfile.frameworkName === frameworkName ? (
                    <ProfileComparisonModal
                      profiles={profiles?.filter(
                        item => item.publishing_status === 'Live'
                      )}
                      profile={guestProfile}
                      comparisonError={comparisonError}
                      setSelectedProfileId={id => {
                        setSelectedProfileId(id);
                      }}
                      redirectToCompare={e => {
                        redirectToCompare(e);
                      }}
                    />
                  ) : (
                    <div className="vf-banner vf-banner--alert vf-banner--info">
                      <div className="vf-banner__content">
                        <p className="vf-banner__text">
                          You have created a{' '}
                          {
                            masterListSource.filter(
                              item => item.title === guestProfile.frameworkName
                            )[0].short_desc
                          }{' '}
                          profile. To compare with profiles on this page, delete
                          this and create a new profile in this framework.
                        </p>
                      </div>
                    </div>
                  )}
                  <hr className="vf-divider | vf-u-fullbleed" />
                  <Link onClick={e => handleDelete(e)} to={`#`}>
                    Delete your profile{' '}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <h4>
                  Create your{' '}
                  {
                    masterListSource.filter(
                      item => item.title === frameworkName
                    )[0].short_desc
                  }{' '}
                  profile{' '}
                </h4>
                {/* <p>
                  Browse your competencies and assess your profile against
                  others
                </p> */}
                <p>
                  Assess your profiles against others and find training
                  resources to fill the gaps
                </p>
                <article>
                  {/* <img
                  alt=""
                  src={user_icon}
                  className="vf-profile__image"
                  width={'auto'}
                  height={'auto'}
                  loading="lazy"
                /> */}

                  <Link
                    className="vf-button vf-button--primary vf-button--sm"
                    to={`/framework/${frameworkName}/${frameworkVersion}/profile/create/guest`}
                  >
                    Add your profile{' '}
                  </Link>
                </article>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileList;
