import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';
import { apiUrl } from '../services/http/http';
import { Link } from 'react-router-dom';

import ReactTooltip from 'react-tooltip';
import { ProfileComparisonModal } from '../../shared/components/ProfileComparisonModal';
import auth from '../services/util/auth';
import { MetaTags } from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import masterListSource from './MasterList';

export const ProfileView = props => {
  let history = useHistory();
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];
  const profileId = props.location.pathname.split('/')[6];

  const [profile, setProfile] = useState(null);
  const [profiles, setProfiles] = useState();
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [framework, setFramework] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  // const [showModal, setShowModal] = useState(false);
  const [comparisonError, setComparisonError] = useState(null);
  const [userFrameworks, setUserFrameworks] = useState([]);
  const [loading, setLoading] = useState(false);

  const redirectToCompare = e => {
    if (selectedProfileId) {
      if (profile.id === 'guest') {
        history.push(
          `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${selectedProfileId}`
        );
      } else if (selectedProfileId === 'guest') {
        history.push(
          `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${
            profile.id
          }`
        );
      } else {
        history.push(
          `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/${
            profile.id
          }/${selectedProfileId}`
        );
      }
    } else {
      setComparisonError('Select a role to compare');
    }
  };

  var competencyView = '';
  var expertise_levels_legend = [];
  var attribute_types = [];
  var frameworkFullName = '';
  var mapping = [];
  var user_roles = auth.currently_logged_in_user.roles;
  var userName = auth.currently_logged_in_user.username;
  var domainsList = '';

  let width2 = '';

  useEffect(() => {
    /**
     * Fetch from the swapi API
     *
     * @returns {Promise<string>} JSON
     */
    const needTimeStamp = () => {
      return auth.currently_logged_in_user.is_logged_in ? Date.now() : '';
    };
    window.vfDropdown();
    const fetchData = async () => {
      setLoading(true);
      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles/?_format=json&source=competencyhub&timestamp=${needTimeStamp()}`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          const profilesExcludingCurrentProfile = findresponse.filter(
            p =>
              // p.id !== props.match.params.id && p.publishing_status === 'Live'
              p.id !== profileId && p.publishing_status === 'Live'
          );
          setProfiles(profilesExcludingCurrentProfile);
        });
      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles?_format=json&id=${profileId}&source=competencyhub&timestamp=${needTimeStamp()}`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setProfile(findresponse);
        });

      await fetch(
        `${apiUrl}/api/version_manager?_format=json&source=competencyhub&${needTimeStamp()}`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFrameworkInfo(findresponse);
        });

      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub&timestamp=${needTimeStamp()}`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFramework(findresponse);
        });

      if (userName) {
        await fetch(`${apiUrl}/api/authorisation/${userName}?_format=json`, {
          method: 'GET',
          credentials: 'include'
        })
          .then(Response => Response.json())
          .then(findresponse => {
            setUserFrameworks(findresponse);
          });
      }
      setLoading(false);
    };
    if (!profile) {
      setTimeout(() => fetchData(), 1000);
    }
  }, [profileId, frameworkVersion, frameworkName, userName, profile]);

  useEffect(() => {
    window.vfDropdown();
  }, [userFrameworks, loading]);

  const getExpertise = competency => {
    if (mapping) {
      let obj = mapping.find(o => o.competency === competency);
      if (obj) {
        if (frameworkInfo) {
          let expertise = frameworkInfo
            .find(info => info.title.toLowerCase() === frameworkName)
            .expertise_levels.find(level => level.id === obj.expertise);
          return expertise;
        }
      } else {
        return '';
      }
    }
  };

  const shortDescFramework = () => {
    let item = masterListSource.filter(item => item.title === frameworkName)[0]
      .short_desc;
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  // const getAttributeStatus = aid => {
  //   if (mapping) {
  //     let attribute_check_status = mapping.find(o =>
  //       o.attributes.find(a => a === aid)
  //     );
  //     return attribute_check_status;
  //   }
  // };

  // {document.addEventListener("DOMContentLoaded", function (event) {
  //   // console.log(document.querySelectorAll('[data-profileid]'))
  //   document.getElementById('bc_location').innerText = 'Prakash'
  // })}

  const getBarWidth = data => {
    let totalLevels = frameworkInfo.find(
      info => info.title.toLowerCase() === frameworkName
    ).expertise_levels.length;
    width2 = data ? 100 / ((totalLevels - 1) / data.rating_level) : 0;
    return width2;
  };

  const generateProfileView = () => {
    if (frameworkInfo) {
      frameworkInfo.map((info, infoIndex) => {
        if (info.title.toLowerCase() === frameworkName) {
          frameworkFullName = info.title;
          info.expertise_levels.map((level, levelIndex) => {
            if (level.title !== 'Not applicable') {
              expertise_levels_legend.push(
                <li key={levelIndex} className="vf-list__item">
                  <div
                    data-tip={level.description ? level.description : 'NA'}
                    data-html={true}
                    data-type="info"
                    data-multiline={true}
                  >
                    <span className="vf-badge vf-badge--tertiary">
                      {level.rating_level}
                    </span>
                    <div> {level.title}</div>
                  </div>
                  <ReactTooltip
                    className="tooltip-custom"
                    style={{ color: 'fff' }}
                  />
                </li>
              );
            }
            return null;
          });
        }
        return null;
      });
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    if (profile) {
      mapping = profile.profile_mapping;

      document.getElementById('bc_location').innerText =
        profile.title + ' - ' + profile.job_title;
    }

    if (framework) {
      competencyView = framework.map((item, itemIndex) =>
        item.domains.map((domain, domainIndex) => (
          <React.Fragment key={itemIndex + domainIndex}>
            <div key={domainIndex}>
              <div>
                <div className="vf-u-padding__top--800" />
                <h4>{domain.title}</h4>{' '}
                <span style={{ visibility: 'hidden' }}>
                  {(domainsList += domain.title + ',')}
                </span>
              </div>
            </div>
            <div>
              <div>
                {domain.competencies.map((competency, compIndex) => (
                  <React.Fragment key={compIndex}>
                    <div className="vf-grid vf-grid__col-4">
                      <div className="vf-grid__col--span-3">
                        <details className="vf-details" close="true">
                          <summary className="vf-details--summary">
                            {competency.title}
                          </summary>
                          {attribute_types.map((attribute_type, typeIndex) => {
                            return (
                              <div key={typeIndex}>
                                <ul className="vf-list">
                                  <li>
                                    <strong> {attribute_type} </strong>
                                    <ul>
                                      {competency.attributes
                                        .filter(
                                          attribute =>
                                            attribute.type === attribute_type
                                        )
                                        .map((attribute, attrIndex) => {
                                          return (
                                            <li
                                              key={attrIndex}
                                              className="vf-list__item"
                                            >
                                              {/* <span style={{ marginRight: '20px' }}>
                                            {getAttributeStatus(
                                              attribute.id,
                                              'profile1'
                                            ) ? (
                                              <i className="icon icon-common icon-check" />
                                            ) : (
                                              '-'
                                            )}
                                          </span> */}
                                              <span>{attribute.title}</span>
                                            </li>
                                          );
                                        })}
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </details>
                      </div>
                      <div className="vf-grid__col--span-1">
                        <div className="fillerbg">
                          <div
                            className="fillerRight"
                            style={{
                              width:
                                getBarWidth(getExpertise(competency.id)) + '%',
                              display: 'flow-root'
                            }}
                          >
                            <span style={{}} className="rating_level_number">
                              {getExpertise(competency.id)
                                ? 'Level: ' +
                                  getExpertise(competency.id).rating_level
                                : 'Not applicable'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))
      );
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>
      {generateProfileView()}
      {profile ? (
        <>
          <MetaTags>
            <title>{`${profile.title} - ${profile.job_title}`}</title>
            <meta
              property="og:title"
              content={`${profile.title} - ${profile.job_title}`}
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`https://competency.ebi.ac.uk${
                props.history.location.pathname
              }`}
            />
            <meta
              property="og:image"
              content="https://acxngcvroo.cloudimg.io/v7/https://cms.competency.ebi.ac.uk/themes/custom/ebi_academy/images/mastheads/CH_Jigsaw.jpg"
            />
            <meta
              property="og:description"
              content="Career profiles describe the background and activities of a specific professional role and list the competencies that a person in that role should have and at which level they are required"
            />
            <meta
              name="description"
              content="Career profiles describe the background and activities of a specific professional role and list the competencies that a person in that role should have and at which level they are required"
            />
            <meta
              property="keywords"
              content={`career profile, career development, ${domainsList}`}
            />
          </MetaTags>
          <Helmet>
            <link rel="canonical" href={props.location.pathname} />
          </Helmet>
          <div key={'profile'} id="profile">
            <div className="vf-u-margin__top--400" />
            <div className="vf-grid vf-grid__col-2">
              <div>
                <h1>
                  {profile.title} - {profile.job_title}
                </h1>
              </div>
              {!loading ? (
                <div>
                  {userFrameworks &&
                  frameworkFullName &&
                  user_roles.includes('framework_manager') &&
                  userFrameworks.length > 0 &&
                  userFrameworks.filter(
                    frm => frm.framework === frameworkFullName
                  ) ? (
                    <div
                      className="vf-dropdown"
                      data-vf-js-dropdown
                      style={{ width: '14rem', float: 'right', display: '' }}
                    >
                      <div className="vf-dropdown__label-container">
                        <div
                          className="vf-dropdown__label"
                          style={{ textAlign: 'left' }}
                        >
                          Manage profile
                        </div>
                        <div>
                          <svg
                            aria-hidden="true"
                            className="arrow-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            height="16"
                            width="16"
                          >
                            <g transform="matrix(0.6666666666666666,0,0,0.6666666666666666,0,0)">
                              <path
                                d="M12,19.5a2.34,2.34,0,0,1-1.73-.78L.46,7.57A1.85,1.85,0,1,1,3.23,5.13l8.58,9.75a.25.25,0,0,0,.38,0l8.58-9.75a1.85,1.85,0,1,1,2.77,2.44L13.73,18.72A2.34,2.34,0,0,1,12,19.5Z"
                                style={{ fill: '#000000' }}
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div
                        className="vf-dropdown__menu-container"
                        style={{
                          position: 'absolute',
                          backgroundColor: 'white'
                        }}
                      >
                        <div className="vf-dropdown-overlay" />
                        <div className="vf-dropdown__menu">
                          <div className="vf-dropdown__menu-item">
                            <Link
                              className="vf-dropdown__menu-item-link"
                              to={`/framework/${frameworkName}/${frameworkVersion}/profile/edit/${profileId}`}
                            >
                              Edit overview{' '}
                              <i className="icon icon-common icon-pencil-alt" />
                            </Link>
                          </div>
                          <div className="vf-dropdown__menu-item">
                            <Link
                              className="vf-dropdown__menu-item-link"
                              to={`/framework/${frameworkName}/${frameworkVersion}/profile/map/${profileId}`}
                            >
                              Assign competencies{' '}
                              <i className="icon icon-common icon-cog" />{' '}
                            </Link>
                          </div>
                          <div className="vf-dropdown__menu-item">
                            <ProfileComparisonModal
                              profiles={profiles}
                              profile={profile}
                              includeGuestProfile={true}
                              comparisonError={comparisonError}
                              setSelectedProfileId={id => {
                                setSelectedProfileId(id);
                              }}
                              redirectToCompare={() => {
                                redirectToCompare();
                              }}
                              dropDown={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ float: 'right' }}>
                      <ProfileComparisonModal
                        profiles={profiles}
                        profile={profile}
                        includeGuestProfile={true}
                        comparisonError={comparisonError}
                        setSelectedProfileId={id => {
                          setSelectedProfileId(id);
                        }}
                        redirectToCompare={() => {
                          redirectToCompare();
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ float: 'right' }}>loading...</div>
              )}
            </div>
            <div className="vf-u-margin__top--400" />

            <div className={profile.publishing_status + ' embl-grid'}>
              <div>
                <center>
                  <img
                    alt=""
                    style={{ display: 'block', maxWidth: '200px' }}
                    src={profile.image[0] ? profile.image[0].url : ''}
                    width={'auto'}
                    height={'auto'}
                    loading="lazy"
                  />
                </center>
                <p />
                <div style={{ textAlign: 'center' }}>
                  {profile.gender !== 'None' ? (
                    <div>
                      <div>
                        {profile.gender &&
                        profile.gender === 'Prefernottosay' ? (
                          <p> Gender: Prefer not to say </p>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {profile.gender &&
                        profile.gender !== 'Prefernottosay' ? (
                          <p> Gender: {profile.gender} </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {profile.age ? profile.age + ' years' : ''}
                </div>
              </div>
              <div>
                <h2>Qualification and background</h2>
                <div>
                  {profile.qualification_background
                    ? Parser(profile.qualification_background)
                    : ''}
                </div>

                <h2>Activities of current role</h2>
                <div>
                  {profile.current_role ? Parser(profile.current_role) : ''}
                </div>

                <div>
                  {profile.additional_information
                    ? Parser(profile.additional_information)
                    : ''}
                </div>
              </div>
            </div>

            <div>
              <div>
                <h3>Scale</h3>
              </div>
              <div>
                <ul className="vf-list legend-inline">
                  {expertise_levels_legend}
                </ul>
              </div>
            </div>
            <hr />
            <div
              className="vf-grid vf-grid__col-4"
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 3000
              }}
            >
              <div className="vf-grid__col--span-3">
                <h4>{shortDescFramework()} competencies </h4>
              </div>
              <div>
                <h4>Proficiencies</h4>
              </div>
            </div>
            <hr />
            {competencyView}
          </div>
        </>
      ) : (
        'Loading profile'
      )}

      <form onSubmit={e => handlePrint(e)}>
        <div>
          <div className="vf-u-margin__top--800" />
          <button
            className="vf-button vf-button--secondary vf-button--sm"
            type="submit"
          >
            Print <i className="icon icon-common icon-print" />
          </button>
          <p>
            [Please enable background graphics in the print preview in order to
            get better print output]
          </p>
        </div>
      </form>
    </div>
  );
};

export const ViewProfile = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profile/view/:id/:alias"
      component={ProfileView}
    />
  </Switch>
);

export default ViewProfile;
