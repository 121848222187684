import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import HttpService from '../services/http/http';
import { apiUrl } from '../services/http/http';
import ProfileService from '../services/profile/profile';

import GuestHelp from './GuestHelp';
import auth from '../services/util/auth';
import masterListSource from './MasterList';

export const ProfileCreateGuest = props => {
  const history = useHistory();
  const profileService = new ProfileService();
  const [title, setTitle] = useState();
  const [age, setAge] = useState('');
  const [currentRole, setCurrentRole] = useState('');
  const [gender, setGender] = useState('');

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileData, setSelectedFileData] = useState([]);
  const [imgpreview, setImgpreview] = useState();
  const [fileSizeError, setFileSizeError] = useState();
  const [fileTypeError, setFileTypeError] = useState();

  const [jobTitle, setJobTitle] = useState('');
  const [qualification, setQualification] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [titleError, setTitleError] = useState(false);
  const [jobTitleError, setJobTitleError] = useState(false);

  const [framework, setFramework] = useState();
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];

  const [profile, setProfile] = useState();
  const [modalOpen, setModelOpen] = useState(false);

  const http = new HttpService();
  const storedProfile = JSON.parse(localStorage.getItem('guestProfile'));

  useEffect(() => {
    // auth.getLoggedInUser().then(user => {
    //   setUser(user);
    // });
    // Set variables from Local Storage to populate form fields onload
    let bootstrap = async () => {
      setProfile(storedProfile);
      setTitle(storedProfile.title);
      setJobTitle(storedProfile.job_title);
      setAge(parseInt(storedProfile.age));
      setGender(storedProfile.gender);
      setQualification(storedProfile.qualification_background);
      setCurrentRole(storedProfile.current_role);
      setAdditionalInfo(storedProfile.additional_information);
      if (storedProfile.image[0]) {
        setSelectedFileData(storedProfile.image);
        setImgpreview(storedProfile.image[0].url);
      }
    };

    // Calling multiple APIs, since data Framework are in two different endpoints
    const fetchData = async () => {
      try {
        const promise1 = http
          .get(
            `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&timestamp=${Date.now()}`
          )
          .then(response1 => response1.data);

        const promise2 = http
          .get(
            `${apiUrl}/api/version_manager?_format=json&timestamp=${Date.now()}`
          )
          .then(response2 => response2.data);

        const [data1] = await Promise.all([promise1, promise2]);
        setFramework(data1);
      } catch (err) {
        console.log(err);
      }
    };

    if (storedProfile && !profile) {
      bootstrap();
    }

    if (!framework) {
      fetchData();
    }
  }, [
    selectedFile,
    frameworkName,
    frameworkVersion,
    http,
    storedProfile,
    framework,
    profile
  ]);

  const handleSubmit = async evt => {
    evt.preventDefault();

    let frameworkId = framework[0].nid;
    let versionNumber = frameworkVersion;

    // If the form doesn't validate, show some errors
    if (validateFormErrors() === true) {
      return null;
    }
    // Check if is Anonymous/Authenticated
    else if (auth.currently_logged_in_user.roles.length === 0) {
      let current_role = currentRole ? currentRole : '';
      let job_title = jobTitle ? jobTitle : '';
      let qualification_background = qualification ? qualification : '';
      let additional_information = additionalInfo ? additionalInfo : '';
      let image = selectedFileData ? selectedFileData : '';

      profileService.createGuestProfile(
        frameworkName,
        frameworkId,
        versionNumber,
        title,
        age,
        current_role,
        gender,
        job_title,
        qualification_background,
        additional_information,
        image
      );
      history.push({
        pathname: `/framework/${frameworkName}/${frameworkVersion}/profile/map/guest/`,
        state: { newProfile: true }
      });
    } else {
      alert(
        'You are currently logged in. To create a guest profile, please logout.'
      );
    }
  };

  const validateFormErrors = () => {
    let errorStatus = false;
    if (!title || title.length < 2) {
      setTitleError(true);
      errorStatus = true;
    } else {
      setTitleError(false);
    }

    if (!jobTitle || jobTitle.length < 3) {
      setJobTitleError(true);
      errorStatus = true;
    } else {
      setJobTitleError(false);
    }

    if (fileTypeError || fileSizeError) {
      errorStatus = true;
    }

    return errorStatus;
  };

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    } else {
      //const objectUrl = URL.createObjectURL(e.target.files[0]);

      if (e.target.files[0].size > 2097152) {
        setFileSizeError(1);
      } else {
        setFileSizeError(undefined);
      }

      if (
        e.target.files[0].type !== 'image/jpeg' &&
        e.target.files[0].type !== 'image/png'
      ) {
        setFileTypeError(1);
      } else {
        setFileTypeError(undefined);
      }

      // convert image file to base64 string. From https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          // Set Image Preview
          setImgpreview(reader.result);

          // Set selected image src
          let img = new Image();
          img.src = reader.result;
          img.addEventListener('load', function() {
            img.width = this.width;
            img.height = this.height;

            setSelectedFileData([
              {
                url: this.src
              }
            ]);
          });
        },
        false
      );
      if (e.target.files) {
        reader.readAsDataURL(e.target.files[0]);
      }

      setSelectedFile(e.target.files[0]);
    }
  };

  const clearimgpreview = e => {
    e.preventDefault();
    setSelectedFile(undefined);
    setImgpreview(undefined);
    setFileTypeError(undefined);
    setFileSizeError(undefined);
    setSelectedFileData(undefined);
    document.getElementById('fileupload').value = '';
  };

  function ButtonLabel() {
    let submitButtonLabel = 'Save and continue';
    if (auth.currently_logged_in_user.roles.length === 0) {
      submitButtonLabel = 'Assign Competencies';
    }

    return (
      <input
        type="submit"
        className="vf-button vf-button--primary vf-button--sm"
        value={submitButtonLabel}
        // onClick={handleSubmit}
      />
    );
  }

  // Check if Fields are not empty hide Help Text
  let nameHelp = 'Must be at least 2 characters long';
  let jobTitleHelp = 'Must be at least 3 characters long';
  if (title) {
    if (title.length > 1) {
      nameHelp = '';
    }
  }

  if (jobTitle) {
    if (jobTitle.length > 2) {
      jobTitleHelp = '';
    }
  }

  const openModal = e => {
    e.preventDefault();
    setModelOpen(true);
  };

  const closeModal = () => {
    //e.preventDefault();
    setModelOpen(false);
  };

  const actionLabel = () => {
    if (storedProfile) {
      return 'Edit your';
    }
    return 'Create a';
  };

  return (
    <div>
      <h1>
        {actionLabel()} profile in{' '}
        {
          masterListSource.filter(item => item.title === frameworkName)[0]
            .short_desc
        }{' '}
      </h1>
      <div className="vf-banner vf-banner--alert vf-banner--info">
        <div className="vf-banner__content">
          <p className="vf-banner__text">
            Your profile will be saved in your browser. This profile will not be
            accessible publicly.
            <button
              className="vf-button vf-button--link"
              onClick={e => openModal(e)}
              style={{
                margin: '0 0 0 0',
                padding: '0 0 0 0',
                fontWeight: 'normal'
              }}
            >
              Get more information
            </button>
          </p>
        </div>
      </div>
      <GuestHelp modalOpen={modalOpen} closeModal={closeModal} />
      <div className="vf-u-margin__bottom--1200" />
      <form
        className="vf-form"
        id="profile_create_form"
        onSubmit={handleSubmit}
      >
        <div className="vf-grid">
          <div />
          <div>
            <span>
              <div className="vf-form__item">
                <label className="vf-form__label" htmlFor="fileupload">
                  Image (optional)
                </label>
                <img
                  alt=""
                  id="imgpreview_image"
                  width="100px"
                  src={imgpreview}
                  height="100px"
                  style={{
                    border: '1px solid #ccc',
                    padding: '5px',
                    width: '30%'
                  }}
                />
                <div>
                  <input type="file" id="fileupload" onChange={onSelectFile} />
                </div>
              </div>
              <div className="vf-u-margin__bottom--600" />

              {imgpreview ? (
                <div>
                  <button
                    className="vf-button vf-button--secondary vf-button--sm"
                    href="#"
                    onClick={e => clearimgpreview(e)}
                  >
                    Clear image x
                  </button>
                </div>
              ) : (
                ''
              )}

              <div className="vf-u-margin__bottom--600" />
              {fileSizeError ? (
                <div className="small callout alert">
                  <em> Image size should not be more than 2 MB </em>{' '}
                </div>
              ) : (
                ''
              )}

              {fileTypeError ? (
                <div className="small callout alert">
                  <em> Only JPG or PNG images are allowed </em>{' '}
                </div>
              ) : (
                ''
              )}
            </span>

            <div className="vf-form__item">
              <label className="vf-form__label" htmlFor="title">
                Name <span style={{ color: '#d32f2f' }}> *</span>
              </label>
              <i>{nameHelp}</i>
              <input
                type="text"
                id="title"
                placeholder="Name"
                onChange={e => setTitle(e.target.value)}
                defaultValue={profile ? profile.title : ''}
                className={
                  'vf-form__input ' +
                  (titleError ? 'vf-form__input--invalid' : '')
                }
                // required="required"
              />
            </div>

            <div className="vf-u-margin__bottom--600" />
            <div className="vf-form__item">
              <label className="vf-form__label" htmlFor="jobTitle">
                Job title <span style={{ color: '#d32f2f' }}> *</span>
              </label>
              <i>{jobTitleHelp}</i>
              <input
                type="text"
                id="jobTitle"
                placeholder="Job title"
                onChange={e => setJobTitle(e.target.value)}
                defaultValue={profile ? profile.job_title : ''}
                className={
                  'vf-form__input ' +
                  (jobTitleError ? 'vf-form__input--invalid' : '')
                }
                // required="required"
              />
            </div>
            <div className="vf-u-margin__bottom--600" />
            <div className="vf-form__item">
              <label className="vf-form__label" htmlFor="text_qualification">
                Qualification and background
              </label>
              <textarea
                id="text_qualification"
                onChange={e => {
                  setQualification(e.target.value);
                }}
                defaultValue={profile ? profile.qualification_background : ''}
                className="vf-form__input"
              />
            </div>
            <div className="vf-u-margin__bottom--600" />
            <div className="vf-form__item">
              <label className="vf-form__label" htmlFor="text_activities">
                Activities of current role
              </label>
              <textarea
                onChange={e => {
                  setCurrentRole(e.target.value);
                }}
                defaultValue={profile ? profile.current_role : ''}
                className="vf-form__input"
                id="text_activities"
              />
            </div>
            <div className="vf-u-margin__bottom--600" />
            <ButtonLabel />
          </div>
          <div />
        </div>
      </form>
    </div>
  );
};

export const Path = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profile/create/guest"
      component={ProfileCreateGuest}
    />
  </Switch>
);

export default Path;
