import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation, Link } from 'react-router-dom';
import { apiUrl } from '../services/http/http';
import masterListSource from './MasterList';

export const ProfilesCompareSummary = props => {
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];
  // const profileId = props.location.pathname.split('/')[6];
  const profileId = props.location.pathname.split('/')[6];

  const [framework, setFramework] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  const location = useLocation();
  // const [summary, setSummary] = useState();

  let profileJobTitle = location.state.profileJobTitle;
  let includeSummary = location.state.includeSummary;
  // let refProfileID = location.state.refProfileID;
  var competencyView = '';
  var attribute_types = [];
  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${apiUrl}/api/version_manager?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFrameworkInfo(findresponse);
        });

      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFramework(findresponse);
        });

      // await fetch(
      //   `${apiUrl}/api/profile_summary/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      // )
      //   .then(Response => Response.json())
      //   .then(findresponse => {
      //     setSummary(findresponse);
      //   });
    };
    fetchData();
  }, [profileId, frameworkVersion, frameworkName]);

  // const findResources = competency_id => {
  //   if (includeSummary) {
  //     let test = summary?.map(item => {
  //       if (item && item.id === competency_id) {
  //         return item.resources.map(resource => {
  //           return (
  //             <li>
  //               <a
  //                 href={resource.resourceLink}
  //                 rel="noopener noreferrer"
  //                 target="_blank"
  //               >
  //                 {' '}
  //                 {resource.resourceName}{' '}
  //               </a>
  //             </li>
  //           );
  //         });
  //       }
  //       return null;
  //     });
  //     let urlToCompetency = includeSummary?.filter(item => item === competency_id ? `/framework/${frameworkName}/${frameworkVersion}/competency/details/${competency_id}#vf-tabs__section--2` : '')

  //     return urlToCompetency;
  //   }
  // };

  const generateSummary = () => {
    if (frameworkInfo) {
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    if (framework) {
      competencyView = framework.map(item =>
        item.domains.map((domain, key) => (
          <div key={key}>
            {/* <div className="vf-grid vf-grid__col-6">
              <div className="vf-grid__col--span-3">
                <h3>{domain.title}</h3>
              </div>
              <div className="vf-grid__col--span-2" />
            </div> */}
            {domain.competencies.map(competency =>
              includeSummary.indexOf(competency.id) !== -1 ? (
                <div
                  key={competency.id}
                  id={competency.id}
                  className="vf-grid vf-grid__col-6"
                >
                  <div className="vf-grid__col--span-4">
                    <p>{competency.title}</p>
                    {/* <details className="vf-details">
                      <summary className="vf-details--summary">
                        {competency.title}
                      </summary>
                       {attribute_types.map(attribute_type => {
                        return (
                          <ul className="vf-list">
                            <li className="vf-list__item">
                              <strong> {attribute_type} </strong>
                              <ul className="vf-list vf-list--unordered">
                                {competency.attributes
                                  .filter(
                                    attribute =>
                                      attribute.type === attribute_type
                                  )
                                  .map(attribute => {
                                    return (
                                      <li className="vf-list__item">
                                        {attribute.title}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          </ul>
                        );
                      })} 
                    </details>*/}
                  </div>
                  {/* <div className="vf-grid__col--span-1">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.8742C9.29632 2.78438 12.2778 1.42211 15 3.8742V14.5C12.2778 12.0479 9.29632 13.4101 8 14.5M8 3.8742C6.70368 2.78438 3.72222 1.42211 1 3.8742V14.5C3.72222 12.0479 6.70368 13.4101 8 14.5M8 3.8742V14.5" stroke="#E58C17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg> Needs improvement
                  </div> */}
                  <div className="vf-grid__col--span-2">
                    <Link
                      to={`/framework/${frameworkName}/${frameworkVersion}/competency/details/${
                        competency.id
                      }#vf-tabs__section--2`}
                    >
                      Find related training resources
                    </Link>
                  </div>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        ))
      );
    }
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  const shortDescFramework = () => {
    let item = masterListSource.filter(item => item.title === frameworkName)[0]
      .short_desc;
    return item.charAt(0).toUpperCase() + item.slice(1);
    // return item;
  };

  return (
    <div>
      {generateSummary()}
      <div className="vf-u-margin__top--800" />
      <h1>Find training resources</h1>
      <div className="vf-u-margin__top--800" />
      <p className="lead">
        Find training resources associated with the competencies that need
        improvement if you want to become a <strong>{profileJobTitle}</strong>.{' '}
        <br />
        You might want to explore other sites to find other resources on similar
        topics to develop these competencies further.
      </p>
      <div className="vf-u-margin__top--1200" />
      <hr />
      <div className="vf-grid vf-grid__col-6">
        <div className="vf-grid__col--span-4">
          <h5>{shortDescFramework()} competencies</h5>
        </div>
        {/* <div className="vf-grid__col--span-1">
          <h5>Status</h5>
        </div> */}
        <div className="vf-grid__col--span-2">
          <h5>Suggested training resources</h5>
        </div>
      </div>
      <hr />
      <div className="vf-u-margin__top--1200" />
      {competencyView}
    </div>
  );
};

export const SummaryPath = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profiles/compare/guest/:refprofile/summary"
      component={ProfilesCompareSummary}
    />
  </Switch>
);

export default SummaryPath;
