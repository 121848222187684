import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';

import { apiUrl } from '../services/http/http';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import user_icon from './user_icon.png';
import { ProfileComparisonModal } from '../../shared/components/ProfileComparisonModal';
import masterListSource from './MasterList';

export const ProfileViewGuest = props => {
  let history = useHistory();
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];

  const [profile, setProfile] = useState();
  const [profiles, setProfiles] = useState();
  const [selectedProfileId, setSelectedProfileId] = useState();
  const [comparisonError, setComparisonError] = useState(null);
  const [framework, setFramework] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  let bannerDismissed = localStorage.getItem('bannerDismissed');

  var competencyView = '';
  var expertise_levels_legend = [];
  var attribute_types = [];
  var mapping = [];
  let width2 = '';

  const redirectToCompare = e => {
    if (selectedProfileId) {
      history.push(
        `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${selectedProfileId}`
      );
    } else {
      setComparisonError('Select a role to compare');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      await setProfile(JSON.parse(localStorage.getItem('guestProfile')));

      await fetch(
        `${apiUrl}/api/version_manager?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFrameworkInfo(findresponse);
        });

      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFramework(findresponse);
        });
      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}/profiles/?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          const profilesExcludingCurrentProfile = findresponse.filter(
            p =>
              // p.id !== props.match.params.id && p.publishing_status === 'Live'
              p.id !== 'guest' && p.publishing_status === 'Live'
          );
          setProfiles(profilesExcludingCurrentProfile);
        });
    };
    fetchData();
  }, [frameworkName, frameworkVersion]);

  const getExpertise = competency => {
    if (mapping) {
      let obj = mapping.find(o => o.competency === competency);
      if (obj) {
        if (frameworkInfo) {
          let expertise = frameworkInfo
            .find(info => info.title.toLowerCase() === frameworkName)
            .expertise_levels.find(level => level.id === obj.expertise);
          // expertise = 'Level: ' + expertise
          return expertise;
        }
      } else {
        return '';
      }
    }
  };

  // const getAttributeStatus = aid => {
  //   if (mapping) {
  //     let attribute_check_status = mapping.find(o =>
  //       o.attributes.find(a => a === aid)
  //     );
  //     return attribute_check_status;
  //   }
  // };

  const getBarWidth = data => {
    let totalLevels = frameworkInfo.find(
      info => info.title.toLowerCase() === frameworkName
    ).expertise_levels.length;

    width2 = data ? 100 / ((totalLevels - 1) / data.rating_level) : 0;
    return width2;
  };

  const generateProfileView = () => {
    if (frameworkInfo) {
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.expertise_levels.map((level, index) => {
            if (level.title !== 'Not applicable') {
              expertise_levels_legend.push(
                <li key={level.rating_level} className="vf-list__item">
                  <div
                    data-tip={level.description ? level.description : 'NA'}
                    data-html={true}
                    data-type="info"
                    data-multiline={true}
                  >
                    <span className="vf-badge vf-badge--tertiary">
                      {' '}
                      {level.rating_level}{' '}
                    </span>{' '}
                    <div> {level.title}</div>
                  </div>
                  <ReactTooltip className="tooltip-custom" />
                </li>
              );
            }
            return null;
          });
        }
        return null;
      });
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    if (profile) {
      mapping = profile.profile_mapping;
      document.getElementById('bc_location').innerText =
        profile.title + ' - ' + profile.job_title;
    }

    if (framework) {
      competencyView = framework.map(item =>
        item.domains.map((domain, domainIndex) => (
          <div key={domainIndex}>
            <h4>{domain.title}</h4>
            {domain.competencies.map((competency, compIndex) => (
              <div key={compIndex}>
                <div className="vf-grid vf-grid__col-4">
                  <div className="vf-grid__col--span-3">
                    <details className="vf-details">
                      <summary className="vf-details--summary">
                        {competency.title}
                      </summary>
                      {/* {competency.title.length > 150
                        ? competency.title
                          .split(' ')
                          .splice(0, 18)
                          .join(' ') + ' ..'
                        : competency.title} */}

                      {attribute_types.map((attribute_type, typeIndex) => {
                        return (
                          <ul className="vf-list" key={typeIndex}>
                            <li className="vf-list__item">
                              <strong> {attribute_type} </strong>
                              <ul className="vf-list vf-list--unordered">
                                {competency.attributes
                                  .filter(
                                    attribute =>
                                      attribute.type === attribute_type
                                  )
                                  .map((attribute, attrIndex) => {
                                    return (
                                      <li
                                        className="vf-list__item"
                                        key={attrIndex}
                                      >
                                        {attribute.title}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </details>
                  </div>
                  <div className="vf-grid__col--span-1">
                    <div className="fillerbg">
                      {getBarWidth(getExpertise(competency.id)) > 0 ? (
                        <div
                          className="fillerRight"
                          style={{
                            width:
                              getBarWidth(getExpertise(competency.id)) + '%',
                            display: 'flow-root'
                          }}
                        >
                          <span className="rating_level_number">
                            {getExpertise(competency.id)
                              ? 'Level: ' +
                                getExpertise(competency.id).rating_level
                              : 'Not applicable'}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            className="rating_level_number"
                            style={{
                              paddingTop: '4px',
                              paddingBottom: '4px',
                              display: 'flow-root'
                            }}
                          >
                            Not applicable
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      );
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const shortDescFramework = () => {
    let item = masterListSource.filter(item => item.title === frameworkName)[0]
      .short_desc;
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  const dismissBanner = () => {
    let bannerDismissed = true;
    localStorage.setItem('bannerDismissed', bannerDismissed);
    document.getElementById('banner').style.display = 'none';
  };

  return (
    <div>
      {generateProfileView()}
      {profile ? (
        <div>
          <div className="vf-u-margin__top--800" />
          {!bannerDismissed ? (
            <div
              id="banner"
              className="vf-banner vf-banner--alert vf-banner--success"
            >
              <div className="vf-banner__content">
                <p className="vf-banner__text">
                  Your profile has been created and stored locally in your
                  browser. This profile is not accessible publicly.
                </p>
                <button
                  aria-label="close notification banner"
                  className="vf-button vf-button--icon vf-button--dismiss | vf-banner__button"
                  onClick={() => dismissBanner()}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <title>dismiss banner</title>
                    <path d="M14.3,12.179a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.442L12.177,9.7a.25.25,0,0,1-.354,0L2.561.442A1.5,1.5,0,0,0,.439,2.563L9.7,11.825a.25.25,0,0,1,0,.354L.439,21.442a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,0,0,2.122-2.121Z" />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="vf-u-margin__top--800" />
          <h1>
            {profile.title} - {profile.job_title}
          </h1>
          <div className="vf-u-margin__top--800" />
          <div style={{ float: 'right' }}>
            <ProfileComparisonModal
              profiles={profiles}
              profile={profile}
              comparisonError={comparisonError}
              setSelectedProfileId={id => {
                setSelectedProfileId(id);
              }}
              redirectToCompare={e => {
                redirectToCompare(e);
              }}
            />
            <ul>
              <li className="profile_navigation">
                <Link
                  className="vf-link"
                  to={`/framework/${frameworkName}/${frameworkVersion}/profile/create/guest`}
                >
                  Edit profile{' '}
                  <i className="icon icon-common icon-pencil-alt" />
                </Link>
              </li>
            </ul>
          </div>

          <div
            className={profile.publishing_status + ' vf-grid vf-grid__col-4'}
          >
            <div className="vf-grid__col--span-1">
              <center>
                <img
                  alt=""
                  style={{ display: 'block', maxWidth: '150px' }}
                  src={profile.image[0] ? profile.image[0].url : user_icon}
                />
              </center>
            </div>

            <div className="vf-grid__col--span-3">
              <h2>Qualification and background</h2>

              {profile.qualification_background
                ? Parser(profile.qualification_background)
                : ''}
              <div className="vf-u-margin__top--800" />
              <h2>Activities of current role</h2>
              {profile.current_role ? Parser(profile.current_role) : ''}
            </div>
            <p />
          </div>

          <h3>Scale</h3>
          <p>A guide to assign your expertise</p>
          <ul className="vf-list legend-inline">{expertise_levels_legend}</ul>

          <hr />
          <div className="vf-grid vf-grid__col-4">
            <div className="vf-grid__col--span-3">
              <h3>{shortDescFramework()} competencies</h3>
            </div>
            <div>
              <h3>Proficiencies</h3>
            </div>
          </div>
          {competencyView}
        </div>
      ) : (
        'Loading profile'
      )}
      <div className="vf-u-margin__top--800" />
      <form onSubmit={e => handlePrint(e)}>
        <div>
          <button
            className="vf-button vf-button--secondary vf-button--sm"
            type="submit"
          >
            Print <i className="icon icon-common icon-print" />
          </button>
          <div className="vf-u-margin__top--400" />
          <p>
            [Please enable background graphics in the print preview in order to
            get better print output]
          </p>
        </div>
      </form>
    </div>
  );
};

export const ViewGuestProfile = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profile/view/guest"
      component={ProfileViewGuest}
    />
  </Switch>
);

export default ViewGuestProfile;
