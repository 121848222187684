import React from 'react';
import ReactModal from 'react-modal';

const GuestHelpHomepage = props => {
  const modalOpen = props.modalOpen;

  const closeModal = e => {
    //e.preventDefault(e);
    props.closeModal();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      backgroundColor: '#f1f1f1'
    }
  };

  return (
    <div>
      <ReactModal isOpen={modalOpen} style={customStyles}>
        <button style={{ float: 'right' }} onClick={e => closeModal(e)}>
          <i className="icon icon-common icon-window-close" />
        </button>
        <h2>
          <i className="icon icon-common icon-exclamation-circle" />
        </h2>
        <p>
          Each competency framework on the website includes a different set of
          competencies, specific for professionals in certain fields.
        </p>
        <p>
          When you create your profile, you will assign levels of competency to
          that specific set. You will then be able to compare your profile to
          the existing career profiles on that framework.
        </p>
        <p>
          As we can only store one profile at a time in your browser, if you
          decide to assess yourself against a different set of competencies, you
          will need to delete it and create a new one within the corresponding
          framework.
        </p>
        <p>
          Before deleting a profile, you can print the profile that you created.
        </p>
      </ReactModal>
    </div>
  );
};

export default GuestHelpHomepage;
