import masterListDev from './masterListDev.json';
import masterList from './masterList.json';

let masterListSource = '';

if (/localhost/.test(window.location.href)) {
  masterListSource = masterListDev;
} else if (/192.168/.test(window.location.href)) {
  masterListSource = masterListDev;
} else if (/dev.competency/.test(window.location.href)) {
  masterListSource = masterListDev;
} else {
  masterListSource = masterList;
}

export default masterListSource;
