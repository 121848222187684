import React from 'react';
// import { useHistory } from 'react-router-dom';
import 'react-svg-radar-chart/build/css/index.css';
import Parser from 'html-react-parser';
import masterListSource from './MasterList';

export const ProfilesCompareButterfly = props => {
  // let history = useHistory();
  const frameworkName = props.frameworkName;
  // const frameworkVersion = props.frameworkVersion;
  // const isGuestProfile = props.isGuestProfile;

  const profile1 = props.profile1;
  const profile2 = props.profile2;
  const framework = props.framework;
  const frameworkInfo = props.frameworkInfo;

  var competencyView = '';
  var expertise_levels = [];
  var expertise_levels_legend = [];
  var attribute_types = [];
  // var frameworkFullName = '';
  var mapping1 = [];
  var mapping2 = [];
  var expertise_array = [];
  var chart_props = [];
  var totalLevels = '';

  var includeSummary = [];

  const getExpertise = (competency, profileid) => {
    let mapping = [];
    if (profileid === 'profile1') {
      mapping = mapping1;
    } else {
      mapping = mapping2;
    }

    let obj = mapping.find(o => o.competency === competency);
    if (obj) {
      if (frameworkInfo) {
        let frm = frameworkInfo.find(
          info => info.title.toLowerCase() === frameworkName
        );

        let expertise = frm.expertise_levels.find(
          level => level.id === obj.expertise
        );
        totalLevels = frm.expertise_levels.length;
        return expertise;
      }
    } else {
      return null;
    }
  };

  const generateProfileView = () => {
    if (frameworkInfo) {
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.expertise_levels.map(
            (level, key) => (expertise_array[key] = level.id) //(expertise_levels[level.id] = level.title)
          );
        }
        return null;
      });
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    let index = 0;
    expertise_levels.map((level, key) => {
      expertise_levels_legend.push(
        "<li class='legend'>  <div class='legend_number'> " +
          index +
          '</div>' +
          level +
          '</li>'
      );
      index++;
      return null;
    });

    if (profile1) {
      mapping1 = profile1.profile_mapping;
    }

    if (profile2) {
      mapping2 = profile2.profile_mapping;
    }

    if (frameworkInfo) {
      frameworkInfo.map(framework => {
        if (framework.title === frameworkName) {
          framework.expertise_levels.map((level, key) => {
            expertise_array[key] = level.id;
            return null;
          });
          totalLevels = framework.expertise_levels.length;
        }
        return null;
      });
    }

    if (framework) {
      competencyView = framework.map(item =>
        item.domains.map(domain =>
          domain.competencies.map((competency, compIndex) => {
            let profile1Expertise = getExpertise(competency.id, 'profile1');
            let profile2Expertise = getExpertise(competency.id, 'profile2');

            if (profile1Expertise && profile2Expertise) {
              includeSummary.push(
                profile1Expertise.rating_level < profile2Expertise.rating_level
                  ? competency.id
                  : null
              );
            } else if (!profile1Expertise && !profile2Expertise) {
              includeSummary.push(null);
            } else if (!profile1Expertise && profile2Expertise) {
              includeSummary.push(competency.id);
            }

            let width1 = profile1Expertise
              ? 100 / ((totalLevels - 1) / profile1Expertise.rating_level)
              : 0;

            let width2 = profile2Expertise
              ? 100 / ((totalLevels - 1) / profile2Expertise.rating_level)
              : 0;

            let status = '';

            if (width1 === 0 && width2 === 0) {
              status = 'Not applicable';
            } else if (width1 === width2 || width1 > width2) {
              status =
                '<i class="icon icon-common icon-check" style="color:green"></i> Meets the level';
            } else if (width1 < width2) {
              status = `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.8742C9.29632 2.78438 12.2778 1.42211 15 3.8742V14.5C12.2778 12.0479 9.29632 13.4101 8 14.5M8 3.8742C6.70368 2.78438 3.72222 1.42211 1 3.8742V14.5C3.72222 12.0479 6.70368 13.4101 8 14.5M8 3.8742V14.5" stroke="#E58C17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg> Needs improvement`;
            }

            return (
              <div key={compIndex}>
                <div className="vf-grid vf-grid__col-6">
                  <div className="vf-grid__col--span-3">
                    <details className="vf-details">
                      <summary className="vf-details--summary">
                        {competency.title}
                      </summary>
                      {attribute_types.map((attribute_type, typeIndex) => {
                        return (
                          <ul className="vf-list" key={typeIndex}>
                            <li className="vf-list__item">
                              <strong> {attribute_type} </strong>
                              <ul className="vf-list vf-list--unordered">
                                {competency.attributes
                                  .filter(
                                    attribute =>
                                      attribute.type === attribute_type
                                  )
                                  .map((attribute, attrIndex) => {
                                    return (
                                      <li
                                        className="vf-list__item"
                                        key={attrIndex}
                                      >
                                        {attribute.title}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </details>
                  </div>
                  <div className="vf-grid__col--span-1">
                    {width1 > 0 ? (
                      <div className="fillerbg">
                        <div
                          className="fillerLeft"
                          style={{
                            width: width1 + '%',
                            marginLeft: 0
                          }}
                        >
                          <span
                            style={{ position: 'relative' }}
                            className="rating_level_number"
                          >
                            {profile1Expertise
                              ? 'Level: ' + profile1Expertise.rating_level
                              : 'Not applicable'}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="fillerbg">
                        <div
                          className="rating_level_number"
                          style={{ paddingTop: '4px', paddingBottom: '4px' }}
                        >
                          Not applicable
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="vf-grid__col--span-1">
                    {width2 > 0 ? (
                      <div className="fillerbg">
                        <div
                          className="fillerRight"
                          style={{
                            width: width2 + '%',
                            marginLeft: 0
                          }}
                        >
                          <span
                            style={{ position: 'relative' }}
                            className="rating_level_number"
                          >
                            {profile2Expertise
                              ? 'Level: ' + profile2Expertise.rating_level
                              : 'Not applicable'}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="fillerbg">
                        <div
                          className="rating_level_number"
                          style={{ paddingTop: '4px', paddingBottom: '4px' }}
                        >
                          Not applicable
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="vf-grid__col--span-1">{Parser(status)}</div>
                </div>
              </div>
            );
          })
        )
      );
    }
  };
  if (profile1) {
    chart_props[0] = {
      key: profile1.id,
      label: profile1.title
    };
  }

  if (profile2) {
    chart_props[1] = {
      key: profile2.id,
      label: profile2.title
    };
  }

  const handlePrint = e => {
    e.preventDefault();
    window.print();
  };

  // const handleSummary = e => {
  //   e.preventDefault();
  //   history.push({
  //     pathname: `/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${profile2.id
  //       }/summary`,
  //     state: {
  //       profileJobTitle: profile1.job_title,
  //       includeSummary: includeSummary,
  //       refProfileID: profile2.id
  //     }
  //   });
  // };

  // const isDevInstance = () => {
  //   if (/localhost/.test(window.location.href)) {
  //     return true;
  //   } else if (/192.168/.test(window.location.href)) {
  //     return true;
  //   } else if (/dev.competency/.test(window.location.href)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const shortDescFramework = () => {
    let item = masterListSource.filter(item => item.title === frameworkName)[0]
      .short_desc;
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  return (
    <div>
      {generateProfileView()}
      <div className="vf-u-margin__top--800" />
      <div
        className="vf-grid vf-grid__col-6"
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 3000
        }}
      >
        <div className="vf-grid__col--span-3">
          <h4>{shortDescFramework()} competencies</h4>
        </div>
        <div className="vf-grid__col--span-1">
          <h4>{profile1.job_title}</h4>
        </div>
        <div className="vf-grid__col--span-1">
          <h4>{profile2.job_title}</h4>
        </div>
        <div className="vf-grid__col--span-1">
          <h4>Status</h4>
        </div>
      </div>

      <hr />
      <div className="vf-grid vf-grid__col-6">
        <div className="vf-grid__col--span-3" />
        <div className="vf-grid__col--span-1">
          <span>Low</span> <span style={{ float: 'right' }}> High</span>
        </div>
        <div className="vf-grid__col--span-1">
          <span>Low</span> <span style={{ float: 'right' }}> High</span>
        </div>
        <div className="vf-grid__col--span-1" />
      </div>

      <div>{competencyView}</div>
      <div className="vf-u-margin__top--800" />
      <div>
        <button
          className="vf-button vf-button--secondary vf-button--sm"
          onClick={e => handlePrint(e)}
        >
          Print <i className="icon icon-common icon-print" />
        </button>
        <p>
          [Please enable background graphics in the print preview in order to
          get better print output]
        </p>
      </div>
    </div>
  );
};

export default ProfilesCompareButterfly;
